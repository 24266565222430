var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-l"},[_c('div',{staticClass:"container flex mx-auto flex-col"},[_c('div',{staticClass:"flex flex-col md:mx-auto"},[_c('span',{staticClass:"flex mx-auto mb-14 text-3xl"},[_vm._v(" "+_vm._s(_vm.$t("headings.ourWorkProgress"))+" ")]),_c('div',{staticClass:"our-work-progress main-progress"},[_c('div',{staticClass:"w-full even-number flex mx-auto"},_vm._l((_vm.progress),function(item2,index){return _c('div',{key:item2.id,staticClass:"flex flex-col justify-end progress"},[(_vm.opened === item2.id)?_c('div',{staticClass:"flex flex-col justify-end transform translate-y-2/3",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"flex py-6 transform -translate-y-1/4"},[_c('div',{staticClass:"mx-3 text-7xl flex items-end"},[_vm._v(" "+_vm._s((index + 1) * 2)+" ")]),_c('p',{},[_vm._v(" "+_vm._s(item2.disc)+" ")])])]):_vm._e(),_c('div',{staticClass:"even-part"},[_c('div',{staticClass:"text-center mt-6 text-5xl text-gray-200"},[_c('div',{staticClass:"cursor-pointer",class:{ 'hidden ': _vm.opened === item2.id },on:{"click":function($event){return _vm.Opened(item2.id)}}},[_vm._v(" "+_vm._s((index + 1) * 2)+" ")])]),(_vm.opened === item2.id)?_c('button',{staticClass:"bt-progress flex mx-auto rounded-full cursor-default bg-primary-600 transform translate-y-2"}):_vm._e(),_c('img',{staticClass:"img-even w-full",attrs:{"alt":"Vue logo","src":require("@/assets/images/TIME2.svg")}})])])}),0),_c('div',{staticClass:"w-full flex odd-number"},_vm._l((_vm.progress2),function(item,index){return _c('div',{key:item.id,staticClass:"progress2"},[_c('div',{staticClass:"odd-part relative"},[_c('img',{staticClass:"img-even w-full",attrs:{"alt":"Vue logo","src":require("@/assets/images/TIME1.svg")}}),(_vm.opened === item.id)?_c('button',{staticClass:"bt-progress cursor-default flex mx-auto rounded-full bg-primary-600 transform -translate-y-2",class:{
                  'progress-one': index === 0
                }}):_vm._e(),_c('div',{staticClass:"text-center mt-6 text-5xl text-gray-200"},[(index == 0)?_c('div',{staticClass:"cursor-pointer one",class:{ hidden: _vm.opened === item.id },on:{"click":function($event){return _vm.Opened(item.id)}}},[_vm._v(" "+_vm._s(index + 1)+" ")]):_c('div',{staticClass:"mt-11 cursor-pointer",class:{ 'hidden ': _vm.opened === item.id },on:{"click":function($event){return _vm.Opened(item.id)}}},[_vm._v(" "+_vm._s(index + 1 + index)+" ")])])]),(_vm.opened === item.id)?_c('div',{staticClass:"flex flex-col justify-end transform -translate-y-1/4"},[(index === 0)?_c('div',{staticClass:"flex py-6 one-disc"},[_c('div',{staticClass:"mx-3 text-7xl flex items-end"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('p',[_vm._v(" "+_vm._s(item.disc)+" ")])]):_c('div',{staticClass:"flex py-6"},[_c('div',{staticClass:"mx-3 text-7xl flex items-end"},[_vm._v(" "+_vm._s(index + 1 + index)+" ")]),_c('p',[_vm._v(" "+_vm._s(item.disc)+" ")])])]):_vm._e()])}),0)]),_c('div',{staticClass:"hidden mini-progress px-11"},[_c('div',{staticClass:"border-progress relative"},_vm._l((_vm.progress3),function(item3){return _c('div',{key:item3.id},[_c('div',{staticClass:"flex progress3"},[_c('button',{staticClass:"rounded-full p-2 btn-progress3 absolute left-0 transform -translate-x-2/3",class:{
                  'bg-primary-600 ': _vm.opened === item3.id,
                  'bg-gray-400': _vm.opened !== item3.id
                },on:{"click":function($event){return _vm.Opened(item3.id)}}}),_c('div',{staticClass:"mx-6 flex"},[_c('div',{staticClass:"text-3xl cursor-pointer opacity-50",class:{ 'opacity-100 ': _vm.opened === item3.id },on:{"click":function($event){return _vm.Opened(item3.id)}}},[_vm._v(" "+_vm._s(item3.id)+" ")]),_c('div',{staticClass:"w-3/4 xs:w-full mx-3 transition duration-300",class:{
                    visible: _vm.opened === item3.id,
                    'invisible max-h-0 ': _vm.opened !== item3.id
                  }},[_vm._v(" "+_vm._s(item3.disc)+" ")])])])])}),0)])]),_c('div',{staticClass:"flex mx-auto mt-20"},[_c('router-link',{attrs:{"to":"/work"}},[(_vm.showourwork !== null)?_c('button',{staticClass:"px-11 py-3 text-secondary-600 rounded-lg border-2 border-primary-600 mx-6"},[_vm._v(" "+_vm._s(_vm.$t("labels.showOurWork"))+" ")]):_vm._e()]),_c('router-link',{attrs:{"to":"/contact-us"}},[_c('button',{staticClass:"px-11 text-xl py-3 text-secondary-600 rounded-lg bg-primary-600"},[_vm._v(" "+_vm._s(_vm.$t("headings.getInTouch"))+" ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }