<template>
  <div class="container-l">
    <div class="container flex mx-auto flex-col">
      <div class="flex flex-col md:mx-auto">
        <span class="flex mx-auto mb-14 text-3xl">
          {{ $t("headings.ourWorkProgress") }}
        </span>
        <div class="our-work-progress main-progress">
          <div class="w-full even-number flex mx-auto">
            <div
              v-for="(item2, index) in progress"
              :key="item2.id"
              class="flex flex-col justify-end progress"
            >
              <div
                v-if="opened === item2.id"
                style="height: 150px"
                class="flex flex-col justify-end transform translate-y-2/3"
              >
                <div class="flex py-6 transform -translate-y-1/4">
                  <div class="mx-3 text-7xl flex items-end">
                    {{ (index + 1) * 2 }}
                  </div>

                  <p class="">
                    {{ item2.disc }}
                  </p>
                </div>
              </div>
              <div class="even-part">
                <div class="text-center mt-6 text-5xl text-gray-200">
                  <div
                    class="cursor-pointer"
                    @click="Opened(item2.id)"
                    :class="{ 'hidden ': opened === item2.id }"
                  >
                    {{ (index + 1) * 2 }}
                  </div>
                </div>

                <button
                  v-if="opened === item2.id"
                  class="bt-progress flex mx-auto rounded-full cursor-default bg-primary-600 transform translate-y-2"
                ></button>

                <img
                  alt="Vue logo"
                  src="@/assets/images/TIME2.svg"
                  class="img-even w-full"
                />
              </div>
            </div>
          </div>

          <div class="w-full flex odd-number">
            <div
              v-for="(item, index) in progress2"
              :key="item.id"
              class="progress2"
            >
              <div class="odd-part relative">
                <img
                  alt="Vue logo"
                  src="@/assets/images/TIME1.svg"
                  style=""
                  class="img-even w-full"
                />

                <button
                  v-if="opened === item.id"
                  class="bt-progress cursor-default flex mx-auto rounded-full bg-primary-600 transform -translate-y-2"
                  :class="{
                    'progress-one': index === 0
                  }"
                ></button>

                <div class="text-center mt-6 text-5xl text-gray-200">
                  <div
                    class="cursor-pointer one"
                    v-if="index == 0"
                    @click="Opened(item.id)"
                    :class="{ hidden: opened === item.id }"
                  >
                    {{ index + 1 }}
                  </div>
                  <div
                    class="mt-11 cursor-pointer"
                    @click="Opened(item.id)"
                    v-else
                    :class="{ 'hidden ': opened === item.id }"
                  >
                    {{ index + 1 + index }}
                  </div>
                </div>
              </div>
              <div
                v-if="opened === item.id"
                class="flex flex-col justify-end transform -translate-y-1/4"
              >
                <div class="flex py-6 one-disc" v-if="index === 0">
                  <div class="mx-3 text-7xl flex items-end">
                    {{ index + 1 }}
                  </div>

                  <p>
                    {{ item.disc }}
                  </p>
                </div>
                <div class="flex py-6" v-else>
                  <div class="mx-3 text-7xl flex items-end">
                    {{ index + 1 + index }}
                  </div>

                  <p>
                    {{ item.disc }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden mini-progress px-11">
          <div class="border-progress relative">
            <div v-for="item3 in progress3" :key="item3.id">
              <div class="flex progress3">
                <button
                  class="rounded-full p-2 btn-progress3 absolute left-0 transform -translate-x-2/3"
                  :class="{
                    'bg-primary-600 ': opened === item3.id,
                    'bg-gray-400': opened !== item3.id
                  }"
                  @click="Opened(item3.id)"
                ></button>
                <div class="mx-6 flex">
                  <div
                    class="text-3xl cursor-pointer opacity-50"
                    @click="Opened(item3.id)"
                    :class="{ 'opacity-100 ': opened === item3.id }"
                  >
                    {{ item3.id }}
                  </div>
                  <div
                    class="w-3/4 xs:w-full mx-3 transition duration-300"
                    :class="{
                      visible: opened === item3.id,
                      'invisible max-h-0 ': opened !== item3.id
                    }"
                  >
                    {{ item3.disc }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex mx-auto mt-20">
        <router-link to="/work">
          <button
            class="px-11 py-3 text-secondary-600 rounded-lg border-2 border-primary-600 mx-6"
            v-if="showourwork !== null"
          >
            {{ $t("labels.showOurWork") }}
          </button>
        </router-link>

        <router-link to="/contact-us">
          <button
            class="px-11 text-xl py-3 text-secondary-600 rounded-lg bg-primary-600"
          >
            {{ $t("headings.getInTouch") }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { showourwork: { default: null }, stages: { default: null } },
  mounted() {
    this.opened = 1;
  },
  methods: {
    Opened(id) {
      this.opened = id;
    }
  },
  data() {
    return {
      opened: "1",
      progress: [
        { disc: "Team brainstorming workshop", id: 2 },
        { disc: "Class Diagram", id: 4 },
        { disc: "Designing", id: 6 },
        { disc: "Testing", id: 8 }
      ],
      progress2: [
        { disc: "Listening from our client", id: 1 },
        { disc: "Analyzing", id: 3 },
        { disc: "Wireframing", id: 5 },
        { disc: "Programming", id: 7 },
        { disc: "Launching the product", id: 9 }
      ],

      progress3: [
        { disc: "Listening from our client", id: 1 },
        { disc: "Team brainstorming workshop", id: 2 },
        { disc: "Analyzing", id: 3 },
        { disc: "Class Diagram", id: 4 },
        { disc: "Wireframing", id: 5 },
        { disc: "Designing", id: 6 },
        { disc: "Programming", id: 7 },
        { disc: "Testing", id: 8 },
        { disc: "Launching the product", id: 9 }
      ]
    };
  }
};
</script>
