<template>
	<div>
		<hero :text="$t('misc.servicesDescription')">
			<img alt="Vue logo" src="@/assets/images/hero.svg" />
		</hero>
		<whatWeDo />

		<our-work-progress :showourwork="$t('labels.showOurWork')" />
	</div>
</template>
<script>
import OurWorkProgress from "@/components/OurWorkProgress.vue";
import Hero from "@/components/Hero.vue";
import WhatWeDo from "@/components/WhatWeDo.vue";

export default {
	components: {
		OurWorkProgress,
		Hero,
		WhatWeDo
	}
};
</script>
